// Classes
import FieldsOptions         from '@/Classes/Records/FieldsOptions'
import { ItemMissingFields } from '@/Classes/Records/ItemMissingFields'
import { RecordHelper }      from '@/Classes/Records/RecordHelper'
import { PrimitiveTools }    from '@/Classes/Static/PrimitiveTools'

// Constants
import { ActionFields } from '@/Constants/ActionFields'
import { AppValues }    from '@/Constants/AppValues'

// Helpers
import { BuilderManager } from '@/Helpers/Components/DataTable/BuilderManager'

// Function Export
export function parseEquipmentsResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data, ActionFields.Edit)

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields('_idEquipment', 'storageName')

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(new FieldsOptions()
		.add('equipmentCode', 'Codigo').isSortable().setAlign(null, 'center').setSearchKey('code')
		.add('equipmentName', 'Descripción').isSortable().setSearchKey('name')
		.add('equipmentMark', 'Marca').isSortable().setAlign(null, 'center').setSearchKey('mark')
		.add('equipmentTypeEquipment', 'Tipo Equipo').isSortable().setAlign(null, 'center').setSearchKey('typeEquipment')
		.add('equipmentTypeArticle', 'Tipo Articulo').isSortable().setAlign(null, 'center').setSearchKey('typeArticle')
		.add('equipmentGroupEquipment', 'Grupo Equipo').isSortable().setAlign(null, 'center').setSearchKey('groupEquipment')
		.add('equipmentGroupArticle', 'Grupo Articulo').isSortable().setAlign(null, 'center').setSearchKey('groupArticle')
		.add('equipmentYear', 'Año Equipo').setAlign(null, 'center')
		.add('zoneCode', 'Código Ubicación').isSortable().setSearchKey('zone.code')
		.add('zoneName', 'Descripción Ubicación').isSortable().setSearchKey('zone.name')
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields('equipmentCode', 'equipmentName', 'equipmentMark', 'equipmentTypeEquipment', 'equipmentTypeArticle', 'equipmentGroupEquipment', 'equipmentGroupArticle', 'equipmentYear', 'zoneCode', 'zoneName')

	// Aplicar un valor a los campos e items si no estan presentes.
	bm.parseItemsMissingFields(new ItemMissingFields()
		.add('equipmentGroupEquipment')
		.add('equipmentMark')
		.add('zoneCode')
		.add('zoneName')
	)

	// Retornar Objeto con los Campos, Items y Acciones.
	return bm.packWithActions()
}

export function parseStackedEquipmentsResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data)

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields('_idZone', '_idStorage', '_idCreator', 'equipments', 'isValid', '_idEquipment', 'equipmentMark', 'equipmentGroupEquipment', 'equipmentTypeArticle', 'equipmentYear', 'storageName', 'zoneCode', 'zoneName', 'equipmentTypeEquipment', 'equipmentGroupArticle')

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(new FieldsOptions()
		.add(`equipmentCode`, 'Código').isSortable().setAlign(null, 'center').setSearchKey('code').showDetailsButton()
		.add(`equipmentName`, 'Descripción').isSortable().setAlign(null, 'center').setSearchKey('name')
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields('equipmentCode', 'equipmentName')

	//Definir los campos a mostrar en el Detalle.
	bm.parseItemsDetail({
		[RecordHelper.setFlags('Marca', 'mark')]: ({ equipmentMark }) => {
			return equipmentMark || AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		[RecordHelper.setFlags('Tipo Equipo', 'typeEquipment')]: ({ equipmentTypeEquipment }) => {
			return equipmentTypeEquipment || AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		[RecordHelper.setFlags('Tipo Articulo', 'typeArticle')]: ({ equipmentTypeArticle }) => {
			return equipmentTypeArticle || AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		[RecordHelper.setFlags('Grupo Equipo', 'groupEquipment')]: ({ equipmentGroupEquipment }) => {
			return equipmentGroupEquipment || AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		[RecordHelper.setFlags('Grupo Articulo', 'groupArticle')]: ({ equipmentGroupArticle }) => {
			return equipmentGroupArticle || AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		['Año Equipo']: ({ equipmentYear }) => {
			return equipmentYear || AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		[RecordHelper.setFlags('Código Ubicación', 'zoneCode', 'zone.code')]: ({ zoneCode }) => {
			return zoneCode || AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		[RecordHelper.setFlags('Descripción Ubicación', 'zoneName', 'zone.name')]: ({ zoneName }) => {
			return zoneName || AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		['Acciones']: ActionFields.Edit
	})

	// Retornar Objeto con los Campos, Items y Acciones.
	return bm.packWithActions()
}

// Function Export
export function parseZonesResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data, ActionFields.EditDelete)

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields('_idZone', '_idStorage', '_idCreator', 'equipments', 'zoneIsValid')

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(new FieldsOptions()
		.add('zoneCode', 'Codigo').isSortable().setAlign(null, 'center').setSearchKey('zone.code')
		.add('zoneName', 'Descripción').isSortable().setSearchKey('zone.name')
		.add('zoneDateStart', 'Fecha Creación').setAlign(null, 'center')
		.add('creatorName', 'Creado por').isSortable().setAlign(null, 'center').setSearchKey('creator.name')
		.add('storageCode', 'Código Planta ').isSortable().setAlign(null, 'center').setSearchKey('storage.code')
		.add('storageName', 'Descripción Planta').isSortable().setAlign(null, 'center').setSearchKey('storage.name')
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields('zoneCode', 'zoneName','zoneDateStart', 'creatorName', 'storageCode', 'storageName')

	// Aplicar un valor a los campos e items si no estan presentes.
	bm.parseItemsMissingFields(new ItemMissingFields()
		.add('zoneCode')
		.add('zoneName')
		.add('creatorName')
		.add('storageCode')
		.add('storageName')
	)

	// Retornar Objeto con los Campos, Items y Acciones.
	return bm.packWithActions()
}

export function parseStackedZonesResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data)

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields('_idZone', '_idStorage', '_idCreator', 'equipments', 'isValid', 'zoneDateStart', 'creatorName', 'storageCode', 'storageName', 'zoneIsValid')

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(new FieldsOptions()
		.add(`zoneCode`, 'Cód. Ubicación').isSortable().setAlign(null, 'center').setSearchKey('zone.code').showDetailsButton()
		.add(`zoneName`, 'Descripción').isSortable().setAlign(null, 'center').setSearchKey('zone.name')
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields('zoneCode', 'zoneName')

	// Definir los campos a mostrar en el Detalle.
	bm.parseItemsDetail({
		['Fecha Creación']: ({ zoneDateStart }) => {
			const date = zoneDateStart ? zoneDateStart : null
			const _date = PrimitiveTools.Dates.parseDateString(date)
			return _date ? `${ _date.date } ${ _date.time }` : AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		[RecordHelper.setFlags('Creado Por', 'creatorName', 'creator.name')]: ({ creatorName }) => {
			return creatorName || AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		[RecordHelper.setFlags('Código Planta', 'storageCode', 'storage.code')]: ({ storageCode }) => {
			return storageCode || AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		[RecordHelper.setFlags('Descripción Planta', 'storageName', 'storage.name')]: ({ storageName }) => {
			return storageName || AppValues.Strings.DEFAULT_EMPTY_STRING
		},	
		['Acciones']: ActionFields.EditDelete
	})

	// Retornar Objeto con los Campos, Items y Acciones.
	return bm.packWithActions()
}