// Classes
import FieldsOptions         from '@/Classes/Records/FieldsOptions'
import { ItemMissingFields } from '@/Classes/Records/ItemMissingFields'

// Constants
import { ActionFields } from '@/Constants/ActionFields'
import { AppValues }    from '@/Constants/AppValues'

// Helpers
import { BuilderManager } from '@/Helpers/Components/DataTable/BuilderManager'

// Function Export
export function parseAssignedEquipmentsResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data, ActionFields.Delete)

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields('_idEquipment', 'storageName', 'zoneCode', 'zoneName')

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(new FieldsOptions()
		.add('equipmentCode', 'Codigo').isSortable().setAlign(null, 'center')
		.add('equipmentName', 'Descripción').isSortable().setAlign(null, 'center')
		.add('equipmentMark', 'Marca').isSortable().setAlign(null, 'center')
		.add('equipmentTypeEquipment', 'Tipo Equipo').isSortable().setAlign(null, 'center')
		.add('equipmentTypeArticle', 'Tipo Articulo').isSortable().setAlign(null, 'center')
		.add('equipmentGroupEquipment', 'Grupo Equipo').isSortable().setAlign(null, 'center')
		.add('equipmentGroupArticle', 'Grupo Articulo').isSortable().setAlign(null, 'center')
		.add('equipmentYear', 'Año').isSortable().setAlign(null, 'center')
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields('equipmentCode', 'equipmentName', 'equipmentMark', 'equipmentTypeEquipment', 'equipmentTypeArticle', 'equipmentGroupEquipment', 'equipmentGroupArticle', 'equipmentYear')

	// Aplicar un valor a los campos e items si no estan presentes.
	bm.parseItemsMissingFields(new ItemMissingFields()
		.add('equipmentGroupEquipment')
		.add('equipmentMark')
	)

	// Retornar Objeto con los Campos, Items y Acciones.
	return bm.packWithActions()
}

export function parseStackedAssignedEquipmentsResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data)

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields('_idEquipment', 'equipmentMark', 'equipmentYear', 'storageName', 'zoneCode', 'zoneName', 'equipmentGroupArticle', 'equipmentGroupEquipment', 'equipmentTypeEquipment', 'equipmentTypeArticle')

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(new FieldsOptions()
		.add(`equipmentCode`, 'Código').isSortable().setAlign(null, 'center').showDetailsButton()
		.add(`equipmentName`, 'Descripción').isSortable().setAlign(null, 'center')
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields('name')

	//Definir los campos a mostrar en el Detalle.
	bm.parseItemsDetail({
		['Tipo Activo']: ({ equipmentTypeEquipment }) => {
			return equipmentTypeEquipment ? equipmentTypeEquipment : AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		['Grupo Activo']: ({ equipmentGroupArticle }) => {
			return equipmentGroupArticle ? equipmentGroupArticle : AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		['Acciones']: ActionFields.Delete
	})

	// Retornar Objeto con los Campos, Items y Acciones.
	return bm.packWithActions()
}

// Function Export
export function parseInternalStoragesResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data)

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields('_idZone', '_idStorage', 'storageCode')

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(new FieldsOptions()
		.add('zoneCode', 'Codigo Ubicación').isSortable().setAlign(null, 'center').setSearchKey('code')
		.add('zoneName', 'Descripción Ubicación').isSortable().setAlign(null, 'center').setSearchKey('name')
		.add('storageName', 'Planta').setAlign(null, 'center')
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields('zoneCode', 'zoneName', 'storageName')

	// Aplicar un valor a los campos e items si no estan presentes.
	bm.parseItemsMissingFields(new ItemMissingFields()
		.add('equipmentGroupEquipment')
		.add('equipmentMark')
	)

	// Retornar Objeto con los Campos, Items y Acciones.
	return bm.packWithActions()
}

export function parseStackedInternalStoragesResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data)

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields('_idZone', '_idStorage', 'storageCode', 'storageName')

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(new FieldsOptions()
		.add(`zoneCode`, 'Cód. Ubicación').isSortable().setAlign(null, 'center').showDetailsButton()
		.add(`zoneName`, 'Ubicación').isSortable().setAlign(null, 'center')
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields('zoneCode', 'zoneName')

	// Definir los campos a mostrar en el Detalle.
	bm.parseItemsDetail({
		['Planta']: ({ storageName }) => {
			return storageName ? storageName : AppValues.Strings.DEFAULT_EMPTY_STRING
		}
	})

	// Retornar Objeto con los Campos, Items y Acciones.
	return bm.packWithActions()
}

// Function Export
export function parseStorageEquipmentsResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data)

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields('_idEquipment')

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(new FieldsOptions()
		.add('equipmentCode', 'Codigo').isSortable().setAlign(null, 'center').setSearchKey('code')
		.add('equipmentName', 'Descripción').isSortable().setSearchKey('name')
		.add('equipmentMark', 'Marca').isSortable().setAlign(null, 'center').setSearchKey('mark')
		.add('equipmentTypeEquipment', 'Tipo Equipo').isSortable().setAlign(null, 'center').setSearchKey('typeEquipment')
		.add('equipmentTypeArticle', 'Tipo Articulo').isSortable().setAlign(null, 'center').setSearchKey('typeArticle')
		.add('equipmentGroupEquipment', 'Grupo Equipo').isSortable().setAlign(null, 'center').setSearchKey('groupEquipment')
		.add('equipmentGroupArticle', 'Grupo Articulo').isSortable().setAlign(null, 'center').setSearchKey('groupArticle')
		.add('equipmentYear', 'Año').setAlign(null, 'center')
		.add('storageName', 'Planta').setAlign(null, 'center')
		.add('zoneCode', 'Codigo Ubicación').isSortable().setAlign(null, 'center').setSearchKey('zone.code')
		.add('zoneName', 'Ubicación').isSortable().setAlign(null, 'center').setSearchKey('zone.name')
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields('equipmentCode', 'equipmentName', 'equipmentMark', 'equipmentTypeEquipment', 'equipmentTypeArticle', 'equipmentGroupEquipment', 'equipmentGroupArticle','equipmentYear', 'storageName', 'zoneCode', 'zoneName')

	// Aplicar un valor a los campos e items si no estan presentes.
	bm.parseItemsMissingFields(new ItemMissingFields()
		.add('equipmentGroupEquipment')
		.add('equipmentMark')
	)

	// Definir los formatos que serán aplicados al valor de la celda.
	bm.parseItemsFormatters({
		zoneCode: (value: any) => {
			return value ? value : 'Sin Asignar'
		},

		zoneName: (value: any) => {
			return value ? value : 'Sin Asignar'
		}
	})

	// Retornar Objeto con los Campos, Items y Acciones.
	return bm.packWithActions()
}

export function parseStackedStorageEquipmentsResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data)

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields('_idEquipment', 'equipmentMark', 'equipmentYear', 'storageName', 'zoneCode', 'zoneName','equipmentGroupArticle', 'equipmentGroupEquipment', 'equipmentTypeEquipment', 'equipmentTypeArticle')

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(new FieldsOptions()
		.add(`equipmentCode`, 'Código').isSortable().setAlign(null, 'center').showDetailsButton()
		.add(`equipmentName`, 'Descripción').isSortable().setAlign(null, 'center')
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields('equipmentCode', 'equipmentName')

	//Definir los campos a mostrar en el Detalle.
	bm.parseItemsDetail({
		['Tipo Activo']: ({ equipmentTypeEquipment }) => {
			return equipmentTypeEquipment ? equipmentTypeEquipment : AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		['Grupo Activo']: ({ equipmentGroupArticle }) => {
			return equipmentGroupArticle ? equipmentGroupArticle : AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		['Marca']: ({ equipmentMark }) => {
			return equipmentMark ? equipmentMark : AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		['Año']: ({ equipmentYear }) => {
			return equipmentYear ? equipmentYear : AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		['Planta']: ({ storageName }) => {
			return storageName ? storageName : AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		['Código Ubicación']: ({ zoneCode }) => {
			return zoneCode ? zoneCode : AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		['Descripción Ubicación']: ({ zoneName }) => {
			return zoneName ? zoneName : AppValues.Strings.DEFAULT_EMPTY_STRING
		}
	})

	// Retornar Objeto con los Campos, Items y Acciones.
	return bm.packWithActions()
}
